import React from "react"
import { graphql } from "gatsby"
import get from "lodash/get"
import Layout from "../components/layout"
import SEO from "../components/seo"

const InfoAndPricingPage = ({ data }) => {
  const title = get(data, "contentfulInfoAndPricingPage.title")
  const html = get(
    data,
    "contentfulInfoAndPricingPage.body.childMarkdownRemark.html"
  )

  const keywords = `info, prices, pricing, prijzen, ${title}`

  return (
    <>
      <SEO title={title} keywords={keywords} />
      <Layout full>
        <section className="container p-6">
          <h1>{title}</h1>
          <div className="html" dangerouslySetInnerHTML={{ __html: html }} />
        </section>
        {/* <Section title="Booking" subtitle="Now!">
          Book now!
        </Section> */}
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query InfoAndPricingPageQuery {
    contentfulInfoAndPricingPage(
      contentful_id: { eq: "1lBTkxPW8XnUiqYEtW4ER6" }
    ) {
      title
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export default InfoAndPricingPage
