/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./layout/header"
import Footer from "./layout/footer"

import "../styles/global.css"

const Layout = ({ children, full }) => {
  const { site } = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const { siteMetadata } = site
  const mainClass = !full ? "container p-6" : ""

  return (
    <>
      <Header title={siteMetadata.title} />
      <main className={mainClass}>{children}</main>

      <a
        style={{ top: '25%' }}
        className="fixed right-0 block shadow-lg rounded-l-md" href="https://www.visitlimburg.be/nl/overnachten/huys" target="_blank">
        <img className="w-12 mb-0 rounded-l-md" src="/toerisme-vlaanderen.jpg" alt="Toerisme vlaanderen" />
      </a>

      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  full: false,
}

export default Layout
